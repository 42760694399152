import PropTypes from 'prop-types';
import React, { createContext, useCallback, useEffect, useState } from 'react';

import axios from '../services/index.js';
import Api from '../helpers/Api';

export const AuthContext = createContext();
const urlAdminMe = Api.urlAdminMe;

export default function AuthContextProvider({ children }) {
  const [me, setMe] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getMe = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await axios.get(`${urlAdminMe}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('user_token')}`
        }
      });
      if (data.data) {
        setMe(data.data.data);
      }
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getMe();
  }, [getMe]);

  return (
    <AuthContext.Provider
      value={{
        me,
        getMe,
        isLoading
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

AuthContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};
