import axios from 'axios';
import LocalStorageService from './LocalStorageService';
const localStorageService = LocalStorageService.getService();

axios.interceptors.request.use(
  (config) => {
    const token = localStorageService.getAccessToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      let needToRedirect = false;
      const token = localStorageService.getAccessToken();
      if (token) {
        needToRedirect = true;
      }
      localStorageService.clearToken();
      console.log('hapus token');

      if (needToRedirect) {
        window.location.href = '/';
      }
      return Promise.reject(error.response.data);
    }

    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);

export default axios;
