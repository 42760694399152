const LocalStorageService = (function(){
  var _service;
  function _getService() {
    if(!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }
  function _setToken(tokenObj) {
    localStorage.setItem('user_token', tokenObj.user_token);
    localStorage.setItem('refresh_token', tokenObj.refresh_token);
  }
  function _getAccessToken() {
    return localStorage.getItem('user_token');
  }
  function _getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }
  function _clearToken() {
    localStorage.removeItem('user_token');
    localStorage.removeItem('refresh_token');
  }
  function _getRole() {
    let token = localStorage.getItem('user_token');
    let role = null;
    if(token){
      try{
        let token_data = JSON.parse(window.atob(token.split('.')[1]));
        role = token_data.role.toLowerCase();
      }catch(err){
        console.log(err);
      }
    }
    return role;
  }
  return {
    getService : _getService,
    setToken : _setToken,
    getAccessToken : _getAccessToken,
    getRefreshToken : _getRefreshToken,
    clearToken : _clearToken,
    getRole : _getRole
  };
})();
export default LocalStorageService;
