const Api = {
  urlLogin: `${process.env.REACT_APP_API_URL}/login-user`,
  urlAdminUser: `${process.env.REACT_APP_API_URL}/admin/user`,
  urlAdminCourier: `${process.env.REACT_APP_API_URL}/admin/user/by-role/kurir`,
  urlAdminBlog: `${process.env.REACT_APP_API_URL}/admin/blog`,
  urlAdminBlogCategory: `${process.env.REACT_APP_API_URL}/admin/blog-category`,
  urlAdminCustomer: `${process.env.REACT_APP_API_URL}/admin/customer`,
  urlAdminRole: `${process.env.REACT_APP_API_URL}/admin/role`,
  urlAdminService: `${process.env.REACT_APP_API_URL}/admin/service`,
  urlAdminServicePrice: `${process.env.REACT_APP_API_URL}/admin/service-price`,
  urlAdminStatus: `${process.env.REACT_APP_API_URL}/admin/status`,
  urlAdminWarehouse: `${process.env.REACT_APP_API_URL}/admin/warehouse`,
  urlAdminVoucher: `${process.env.REACT_APP_API_URL}/admin/voucher`,
  urlAdminTransaction: `${process.env.REACT_APP_API_URL}/admin/transaction`,
  urlAdminTransactionCreate: `${process.env.REACT_APP_API_URL}/admin/transaction/create-customer-order`,
  urlAdminFaqs: `${process.env.REACT_APP_API_URL}/admin/faqs`,
  urlAdminInventory: `${process.env.REACT_APP_API_URL}/admin/inventory`,
  urlAdminReport: `${process.env.REACT_APP_API_URL}/admin/report`,
  urlBase: `${process.env.REACT_APP_API_URL}/`,
  urlAdminMe: `${process.env.REACT_APP_API_URL}/admin/me`,
  urlOngkir: `${process.env.REACT_APP_API_URL}/ongkir`,
  urlFaqs: `${process.env.REACT_APP_API_URL}/faqs`,
  urlState: `${process.env.REACT_APP_API_URL}/state`,
  urlCity: `${process.env.REACT_APP_API_URL}/city`,
  urlDistrict: `${process.env.REACT_APP_API_URL}/district`
};

export default Api;
