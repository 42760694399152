/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'id';
export const localeOptions = [
  { id: 'id', name: 'Bahasa Indonesia', direction: 'ltr' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

export const searchPath = '#';
export const searchInvoicePath = '/app/invoice/detail';
export const servicePath = 'https://api.coloredstrategies.com';


export const themeColorStorageKey = '__theme_selected_color';
export const defaultColor = 'ahsan.red';
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isDemo = false;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];

export const wa_send_order_link_template = `*Bismillah,*

Yey. *Alhamdulillah*, Uma/Aba bisa melakukan order pickup tanpa login dan tanpa khawatir lupa password.

Klik link di bawah ini untuk melakukan order pickup:

{generated_link}

Link token ini berlaku selama 6 bulan. Mohon chat ini di simpan atau tambahkan ke dalam pesan berbintang. Klik link diatas ketika akan melakukan order pickup atau tracking.

Jika uma/aba ada kesulitan, jangan sungkan untuk chat kami lagi ya.`;